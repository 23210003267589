import { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import websiteInfo from './../utils/websiteInfo';

function Home() {
  const { theme, site } = websiteInfo;
  const canvasRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (!canvasRef.current) return;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(theme.colors.background);
    scene.fog = new THREE.Fog(theme.colors.background, 1, 120);
    
    const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ 
      canvas: canvasRef.current,
      antialias: true,
      alpha: false
    });

    renderer.setClearColor(theme.colors.background, 1);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const generateTerrainPoints = () => {
      const points = [];
      const colors = [];
      const color = new THREE.Color(theme.colors.accent);
      
      const size = 150;
      const density = 0.3;
      
      for (let x = -size; x < size; x += density) {
        for (let z = -size; z < size; z += density) {
          const baseHeight = Math.sin(x * 0.03) * Math.cos(z * 0.03) * 12;
          const detail = Math.sin(x * 0.15) * Math.cos(z * 0.15) * 3;
          const y = baseHeight + detail;

          points.push(x, y, z);
          colors.push(color.r, color.g, color.b);
        }
      }

      return { points, colors };
    };

    const { points, colors } = generateTerrainPoints();
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(points, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    const material = new THREE.PointsMaterial({
      size: 0.15,
      vertexColors: true,
      opacity: 0.7,
      transparent: true
    });

    const terrain = new THREE.Points(geometry, material);
    scene.add(terrain);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    camera.position.set(0, 25, 40);

    let time = 0;
    const animate = () => {
      requestAnimationFrame(animate);
      time += 0.0008;

      const radius = 60;
      const height = 35 + Math.sin(time) * 8;
      
      camera.position.x = Math.sin(time) * radius;
      camera.position.z = Math.cos(time) * radius;
      camera.position.y = height;
      
      camera.lookAt(0, 0, 0);

      const positions = geometry.attributes.position.array;
      for (let i = 1; i < positions.length; i += 3) {
        positions[i] += Math.sin(time + positions[i-1] * 0.01) * 0.02;
      }
      geometry.attributes.position.needsUpdate = true;

      renderer.render(scene, camera);
    };

    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      renderer.dispose();
    };
  }, [theme.colors.accent, theme.colors.background]);

  return (
    <>
      <div 
        className="fixed top-0 left-0 w-full h-full"
        style={{ backgroundColor: theme.colors.background, zIndex: 0 }}
      />
      <canvas 
        ref={canvasRef} 
        className="fixed top-0 left-0 w-full h-full opacity-60"
        style={{ zIndex: 1 }}
      />
      
      <div className="relative min-h-screen flex flex-col" style={{ zIndex: 2 }}>
        <div className="flex-grow flex items-center justify-center px-6">
          <div className="max-w-5xl mx-auto text-center">
            <div className="space-y-6 mb-16">
              <h1 
                className={`text-5xl md:text-7xl font-bold tracking-tight leading-none transform transition-all duration-1000 ease-out ${
                  isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
                }`}
                style={{ color: theme.colors.text.primary }}
              >
                <span className="block mb-4 transform hover:scale-105 transition-transform duration-300">
                  Old Problems
                </span>
                <span 
                  className="block"
                  style={{
                    background: `linear-gradient(135deg, ${theme.colors.accent}, ${theme.colors.text.primary})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  Innovative Solutions
                </span>
              </h1>
              
              <p 
                className={`mx-auto transform transition-all duration-1000 delay-300 ease-out ${
                  isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
                }`}
                style={{ 
                  color: theme.colors.text.secondary,
                  fontSize: theme.font.sizes.xl,
                  maxWidth: '36rem',
                  lineHeight: '1.8'
                }}
              >
                {site.description}
                <span 
                  className="block mt-4 font-medium"
                  style={{
                    background: `linear-gradient(135deg, ${theme.colors.text.primary}, ${theme.colors.accent})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  We Imagine it. We Build it. You Grow.
                </span>
              </p>
            </div>

            <div 
              className={`flex flex-col sm:flex-row justify-center gap-6 transform transition-all duration-1000 delay-500 ease-out ${
                isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
              }`}
            >
              <a 
                href={site.links.github}
                style={{ 
                  backgroundColor: theme.colors.accent,
                  color: theme.colors.background,
                  padding: '1rem 3rem',
                  borderRadius: theme.borderRadius.sm,
                  transition: theme.style.transitions.default
                }}
                className="inline-block transform hover:scale-105 hover:shadow-lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Our Projects
              </a>
              <a 
                href="#about"
                style={{ 
                  backgroundColor: 'transparent',
                  color: theme.colors.text.accent,
                  border: `2px solid ${theme.colors.text.accent}`,
                  padding: '1rem 3rem',
                  borderRadius: theme.borderRadius.sm,
                  transition: theme.style.transitions.default,
                  backdropFilter: 'blur(5px)'
                }}
                className="inline-block hover:bg-opacity-10 hover:bg-white hover:shadow-lg"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
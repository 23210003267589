export default {
  "theme": {
    "colors": {
      "primary": "#00b894",
      "secondary": "#005c4b",
      "accent": "#00e6bc",
      "background": "#0a0c0e",
      "surface": "#141618",
      "text": {
        "primary": "#e6e6e6",
        "secondary": "#808080",
        "accent": "#00e6bc"
      },
      "border": {
        "primary": "#1a1d20",
        "secondary": "#2d3136"
      }
    },

    "font": {
      "primary": "'SF Mono', 'Roboto Mono', 'Source Code Pro', monospace",
      "code": "'SF Mono', Menlo, Monaco, Consolas, 'Courier New', monospace",
      "sizes": {
        "xs": "0.75rem",
        "sm": "0.875rem",
        "base": "1rem",
        "lg": "1.125rem",
        "xl": "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem"
      }
    },

    "spacing": {
      "container": {
        "max": "7xl",
        "padding": {
          "default": "1.5rem",
          "sm": "2rem"
        }
      }
    },

    "borderRadius": {
      "sm": "2px",
      "default": "3px",
      "lg": "4px",
      "full": "9999px"
    },

    "style": {
      "shadows": {
        "sm": "0 2px 4px rgba(0, 232, 188, 0.05)",
        "default": "0 4px 6px rgba(0, 232, 188, 0.1)",
        "lg": "0 8px 16px rgba(0, 232, 188, 0.15)"
      },
      "transitions": {
        "default": "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        "fast": "all 0.1s cubic-bezier(0.4, 0, 0.2, 1)",
        "slow": "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
      }
    }
  },

  "site": {
    "name": "Labyrica",
    "description": "Building innovative digital solutions for tomorrow's challenges",
    "author": "Roberto Bendinelli",
    "links": {
      "github": "https://github.com/robertobendi",
      "documentation": "/docs",
      "contact": "mailto:contact@labyrica.com"
    }
  },

  "navigation": {
    "menu": [
      {
        "label": "Home",
        "path": "/"
      },
      {
        "label": "About",
        "path": "/about"
      },
      {
        "label": "Projects",
        "path": "/projects"
      }
    ]
  }
}
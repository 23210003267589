import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import websiteInfo from './../../utils/websiteInfo';
import logo from './../../assets/img/logo.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { theme } = websiteInfo;
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const getBackgroundStyle = () => {
    const baseColor = scrolled ? theme.colors.surface : theme.colors.background;
    // Convert hex to rgba for transparency
    const rgba = `rgba(${parseInt(baseColor.slice(1,3), 16)}, ${parseInt(baseColor.slice(3,5), 16)}, ${parseInt(baseColor.slice(5,7), 16)}, ${scrolled ? 0.8 : 0.7})`;
    return rgba;
  };

  return (
    <nav style={{ 
      backgroundColor: getBackgroundStyle(),
      fontFamily: theme.font.primary,
      borderBottom: `1px solid ${scrolled ? 'rgba(45, 49, 54, 0.8)' : 'rgba(26, 29, 32, 0.6)'}`,
      transition: theme.style.transitions.default,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 50,
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)', // For Safari support
    }}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <Link 
            to="/" 
            className="flex items-center space-x-3 group"
            style={{ transition: theme.style.transitions.fast }}
          >
            <img 
              src={logo} 
              alt="Labyrica Logo" 
              className="h-8 w-auto transform group-hover:scale-105 transition-transform"
              style={{ filter: 'brightness(1.1)' }}
            />
          </Link>

          <div className="hidden md:flex items-center space-x-8">
            {websiteInfo.navigation.menu.map((item) => {
              const isActive = location.pathname === item.path;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  style={{ 
                    color: isActive ? theme.colors.accent : theme.colors.text.secondary,
                    fontSize: theme.font.sizes.sm,
                    transition: theme.style.transitions.fast,
                    position: 'relative'
                  }}
                  className="hover:text-white py-2"
                >
                  {item.label}
                  {isActive && (
                    <span
                      className="absolute bottom-0 left-0 w-full h-0.5 transform scale-x-100"
                      style={{ 
                        backgroundColor: theme.colors.accent,
                        transition: theme.style.transitions.fast,
                        opacity: 0.9
                      }}
                    />
                  )}
                </Link>
              );
            })}
          </div>

          <button
            onClick={() => setIsOpen(!isOpen)}
            style={{ 
              color: theme.colors.text.secondary,
              transition: theme.style.transitions.fast
            }}
            className="md:hidden p-2 rounded-md hover:bg-opacity-10 hover:bg-white"
            aria-label="Toggle menu"
          >
            {isOpen ? (
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            )}
          </button>
        </div>

        <div 
          className={`md:hidden absolute left-0 right-0 p-4 transform transition-transform duration-200 ease-in-out ${
            isOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
          }`}
          style={{ 
            backgroundColor: `rgba(${parseInt(theme.colors.surface.slice(1,3), 16)}, ${parseInt(theme.colors.surface.slice(3,5), 16)}, ${parseInt(theme.colors.surface.slice(5,7), 16)}, 0.95)`,
            borderBottom: '1px solid rgba(26, 29, 32, 0.8)',
            boxShadow: theme.style.shadows.lg,
            backdropFilter: 'blur(12px)',
            WebkitBackdropFilter: 'blur(12px)'
          }}
        >
          <div className="flex flex-col space-y-4">
            {websiteInfo.navigation.menu.map((item) => {
              const isActive = location.pathname === item.path;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  style={{ 
                    color: isActive ? theme.colors.accent : theme.colors.text.secondary,
                    fontSize: theme.font.sizes.base,
                    transition: theme.style.transitions.fast,
                    padding: '0.5rem 0'
                  }}
                  className="hover:text-white flex items-center space-x-2"
                  onClick={() => setIsOpen(false)}
                >
                  <span>{item.label}</span>
                  {isActive && (
                    <span
                      className="w-1 h-1 rounded-full"
                      style={{ backgroundColor: theme.colors.accent }}
                    />
                  )}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
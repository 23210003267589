import React, { useState, useEffect } from 'react';
import websiteInfo from './../utils/websiteInfo';
import { Users, Brain, Target, Network, MessageCircle, TrendingUp } from 'lucide-react';

function Projects() {
  const { theme } = websiteInfo;
  const [isVisible, setIsVisible] = useState(false);

  const features = [
    {
      icon: Users,
      tag: "Talent Management",
      title: "Retain & Grow",
      description: "Transform employees into innovators with personalized growth paths"
    },
    {
      icon: Brain,
      tag: "Smart Platform",
      title: "AI-Powered Insights",
      description: "Make data-driven decisions to improve workplace dynamics"
    },
    {
      icon: Target,
      tag: "Efficiency",
      title: "Focus What Matters",
      description: "Streamline operations and eliminate redundant workflows"
    },
    {
      icon: Network,
      tag: "Community",
      title: "Build Connections",
      description: "Foster authentic workplace relationships and culture"
    },
    {
      icon: MessageCircle,
      tag: "Communication",
      title: "Engage Better",
      description: "Smart algorithms ensure your message reaches the right people"
    },
    {
      icon: TrendingUp,
      tag: "Growth",
      title: "Scale Culture",
      description: "Build a workplace that grows stronger with every addition"
    }
  ];

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="min-h-screen" style={{ backgroundColor: theme.colors.background }}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div 
          className={`mb-16 transform transition-all duration-1000 ease-out ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
          }`}
        >

        </div>

        <div 
          className={`transform transition-all duration-1000 delay-300 ease-out ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
          }`}
        >
          <div 
            className="relative p-8 md:p-12 rounded-lg group hover:transform hover:scale-[1.01] transition-all duration-300"
            style={{
              backgroundColor: `${theme.colors.surface}90`,
              borderLeft: `3px solid ${theme.colors.accent}`
            }}
          >
            <div 
              className="absolute inset-0 rounded-lg opacity-0 group-hover:opacity-10 transition-opacity duration-300"
              style={{
                background: `linear-gradient(45deg, ${theme.colors.accent}, transparent)`
              }}
            />

            <div className="relative z-10">
              {/* Header */}
              <div className="mb-12">
                <div 
                  className="inline-block px-4 py-1 rounded-full text-sm mb-4"
                  style={{ 
                    backgroundColor: `${theme.colors.accent}20`,
                    color: theme.colors.accent
                  }}
                >
                  Professional Social Network
                </div>
                <h2 
                  className="text-3xl md:text-4xl font-bold mb-4"
                  style={{ color: theme.colors.accent }}
                >
                  Daedal
                </h2>
                <p 
                  className="text-xl md:text-2xl"
                  style={{ 
                    color: theme.colors.text.primary,
                    maxWidth: '800px'
                  }}
                >
                  Reimagining workplace connectivity for tomorrow's companies
                </p>
              </div>

              {/* Features Grid */}
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                {features.map((feature, index) => (
                  <div 
                    key={index}
                    className={`transform transition-all duration-700 ease-out ${
                      isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
                    }`}
                    style={{ transitionDelay: `${500 + index * 100}ms` }}
                  >
                    <div className="flex items-start space-x-4">
                      <div 
                        className="p-2 rounded"
                        style={{ backgroundColor: `${theme.colors.accent}15` }}
                      >
                        <feature.icon 
                          size={24}
                          style={{ color: theme.colors.accent }}
                        />
                      </div>
                      <div>
                        <div 
                          className="text-xs font-medium mb-1 px-2 py-0.5 rounded inline-block"
                          style={{ 
                            backgroundColor: `${theme.colors.surface}`,
                            color: theme.colors.text.secondary
                          }}
                        >
                          {feature.tag}
                        </div>
                        <h3 
                          className="text-base font-medium mb-1"
                          style={{ color: theme.colors.text.primary }}
                        >
                          {feature.title}
                        </h3>
                        <p 
                          className="text-sm"
                          style={{ color: theme.colors.text.secondary }}
                        >
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* CTA */}
              <div className="flex items-center space-x-6">
                <a
                  href="#contact"
                  className="inline-flex items-center group"
                  style={{ 
                    backgroundColor: theme.colors.accent,
                    color: theme.colors.background,
                    padding: '0.875rem 2rem',
                    borderRadius: theme.borderRadius.sm,
                    transition: theme.style.transitions.default
                  }}
                >
                  <span className="font-medium">Learn More</span>
                  <span className="ml-2 transform transition-transform duration-300 group-hover:translate-x-1">→</span>
                </a>
                <p style={{ color: theme.colors.text.secondary }} className="text-sm">
                  Currently in development
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
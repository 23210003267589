import React, { useState, useEffect } from 'react';
import websiteInfo from './../utils/websiteInfo';

function About() {
  const { theme } = websiteInfo;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="min-h-screen relative" style={{ backgroundColor: theme.colors.background }}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Header Section */}
        <div 
          className={`text-center mb-12 transform transition-all duration-1000 ease-out ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
          }`}
        >
          <h1 
            className="text-4xl md:text-5xl font-bold mb-4"
            style={{ color: theme.colors.text.primary }}
          >
            The Founders
          </h1>
          <p style={{ 
            color: theme.colors.text.secondary,
            fontSize: theme.font.sizes.lg,
            maxWidth: '36rem',
            margin: '0 auto'
          }}>
            Two minds, one vision: creating innovative solutions for tomorrow's challenges.
          </p>
        </div>

        {/* Founders Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Federico's Card */}
          <div 
            className={`transform transition-all duration-1000 delay-300 ease-out ${
              isVisible ? 'translate-x-0 opacity-100' : '-translate-x-10 opacity-0'
            }`}
          >
            <div className="group p-6 rounded-lg relative" style={{
              backgroundColor: `${theme.colors.surface}80`,
              borderLeft: `2px solid ${theme.colors.accent}`
            }}>
              {/* Profile */}
              <div className="flex items-center mb-6">
                <div className="w-16 h-16 relative mr-4">
                  <div 
                    className="absolute inset-0 rounded-full"
                    style={{
                      border: `2px solid ${theme.colors.accent}`,
                      opacity: 0.5
                    }}
                  />
                  <div 
                    className="w-full h-full rounded-full flex items-center justify-center"
                    style={{ backgroundColor: theme.colors.surface }}
                  >
                    <span style={{ color: theme.colors.text.primary }}>FT</span>
                  </div>
                </div>
                <div>
                  <h2 className="text-2xl font-bold" style={{ color: theme.colors.accent }}>
                    Federico Tarpini
                  </h2>
                  <h3 style={{ color: theme.colors.text.secondary }}>
                    Critical Thinker since 1999
                  </h3>
                </div>
              </div>

              {/* Content */}
              <p className="mb-4" style={{ color: theme.colors.text.secondary, lineHeight: '1.6' }}>
                Passionate about politics, probably too much, I constantly observate the world around me, striving to learn and understand more and more about it. One day I'd also enjoy fixing a small part of it; hoping Labyrica may become a part of that process. In my Free Time I work as a Consultant in European Funds for Public Sector. I have experiences in Public Institutions, Public Affairs, Research for Industrial and Energy policies, EU funds and EU policies.
              </p>
              <a
                href="mailto:tarpini.federico@gmail.com"
                className="inline-flex items-center space-x-2 group"
                style={{ color: theme.colors.accent }}
              >
                <span>tarpini.federico@gmail.com</span>
                <span className="transform transition-transform duration-300 group-hover:translate-x-1">→</span>
              </a>
            </div>
          </div>

          {/* Roberto's Card */}
          <div 
            className={`transform transition-all duration-1000 delay-500 ease-out ${
              isVisible ? 'translate-x-0 opacity-100' : 'translate-x-10 opacity-0'
            }`}
          >
            <div className="group p-6 rounded-lg relative" style={{
              backgroundColor: `${theme.colors.surface}80`,
              borderLeft: `2px solid ${theme.colors.accent}`
            }}>
              {/* Profile */}
              <div className="flex items-center mb-6">
                <div className="w-16 h-16 relative mr-4">
                  <div 
                    className="absolute inset-0 rounded-full"
                    style={{
                      border: `2px solid ${theme.colors.accent}`,
                      opacity: 0.5
                    }}
                  />
                  <div 
                    className="w-full h-full rounded-full flex items-center justify-center"
                    style={{ backgroundColor: theme.colors.surface }}
                  >
                    <span style={{ color: theme.colors.text.primary }}>RB</span>
                  </div>
                </div>
                <div>
                  <h2 className="text-2xl font-bold" style={{ color: theme.colors.accent }}>
                    Roberto Bendinelli
                  </h2>
                  <h3 style={{ color: theme.colors.text.secondary }}>
                    Technical Architect
                  </h3>
                </div>
              </div>

              {/* Content */}
              <p className="mb-4" style={{ color: theme.colors.text.secondary, lineHeight: '1.6' }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              </p>
              <a
                href="mailto:contact@example.com"
                className="inline-flex items-center space-x-2 group"
                style={{ color: theme.colors.accent }}
              >
                <span>contact@example.com</span>
                <span className="transform transition-transform duration-300 group-hover:translate-x-1">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;